<template>
  <div>
    <!--  BEGIN NAVBAR  -->
    <Header></Header>
    <!--  END NAVBAR  -->

    <!--  BEGIN MAIN CONTAINER  -->
    <div class="main-container" id="container"
      :class="[!$store.state.is_show_sidebar ? 'sidebar-closed sbar-open' : '', $store.state.menu_style === 'collapsible-vertical' ? 'collapsible-vertical-mobile' : '']">
      <!--  BEGIN OVERLAY  -->
      <div class="overlay" :class="{ show: !$store.state.is_show_sidebar }"
        @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"></div>
      <div class="search-overlay" :class="{ show: $store.state.is_show_search }"
        @click="$store.commit('toggleSearch', !$store.state.is_show_search)"></div>
      <!-- END OVERLAY -->

      <!--  BEGIN SIDEBAR  -->
      <Sidebar></Sidebar>
      <!--  END SIDEBAR  -->

      <!--  BEGIN CONTENT AREA  -->
      <div id="content" class="main-content">
        <div class="layout-px-spacing">
          <div v-if="showKYCStatus" class="row layout-top-spacing">
            <KYCStatus />
          </div>

          <router-view />
        </div>

        <!-- BEGIN FOOTER -->
        <Footer></Footer>
        <!-- END FOOTER -->
      </div>
      <!--  END CONTENT AREA  -->

      <!-- BEGIN APP SETTING LAUNCHER -->
      <app-settings />
      <!-- END APP SETTING LAUNCHER -->
    </div>
  </div>
</template>

<script setup>
import '@/assets/sass/app.scss';

import Header from '@/components/layout/header.vue';
import Sidebar from '@/components/layout/sidebar.vue';
import Footer from '@/components/layout/footer.vue';
import appSettings from '@/components/app-settings.vue';
import KYCStatus from '@/components/account/KYCStatus.vue';

import { onMounted, computed, provide, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import authService from '@/services/auth.service';
import userService from '@/services/user.service';

const store = useStore();
const route = useRoute();
const router = useRouter();

const currentUser = computed(() => store.state.user);
const showKYCStatus = ref(false);

const logOut = () => {
  authService.logout();
  router.push({ name: 'auth.login' });
}

provide('currentUser', currentUser);
provide('logOut', logOut);

const blacklistKYCStatusPage = ['faq', 'account.kyc']
const checkKYCStatus = (route_to) => {
  if (blacklistKYCStatusPage.includes(route_to.name)) {
    showKYCStatus.value = false;
    return;
  }

  if (store.state.user) {
    if (store.state.user.user_sumsub.review_status == "completed" && store.state.user.user_sumsub.review_result == 1) {
      showKYCStatus.value = false;
    } else {
      userService.getUser(store.state.user.id).then((user) => {
        store.commit('setUser', user);
        if (user.user_sumsub.review_status == "completed" && user.user_sumsub.review_result == 1) {
          showKYCStatus.value = false;
        } else {
          showKYCStatus.value = true;
        }
      }).catch((err) => {
        console.log(err);
        showKYCStatus.value = true;
      });
    }
  }
}

const withdraws = ref([])

const getWithdrawStatusClass = (slug) => {
  if (slug === 'PENDING') return 'warning';
  if (slug === 'IN_REVIEW') return 'info';
  if (slug === 'COMPLETED') return 'success';
  if (slug === 'REJECTED') return 'danger';
}

const addWithdraw = (withdraw) => {
  var isExist = false;

  withdraws.value.forEach((element) => {
    if (element.id === withdraw.id) {
      isExist = true;

      element.amount = withdraw.amount;
      element.iban = withdraw.iban;
      element.bic = withdraw.bic;

      if (withdraw.withdraw_status) {
        element.status = { key: withdraw.withdraw_status.name, class: getWithdrawStatusClass(withdraw.withdraw_status.slug) };
      } else {
        element.status = { key: "Pending", class: getWithdrawStatusClass("PENDING") };
      }
    }
  })

  if (!isExist) {
    var status = null;
    if (withdraw.withdraw_status) {
      status = { key: withdraw.withdraw_status.name, class: getWithdrawStatusClass(withdraw.withdraw_status.slug) };
    } else {
      status = { key: "Pending", class: getWithdrawStatusClass("PENDING") };
    }

    withdraws.value.push({
      id: withdraw.id,
      amount: withdraw.amount,
      iban: withdraw.iban,
      bic: withdraw.bic,
      status: status
    })
  }
}

provide('withdraws', withdraws)
provide('addWithdraw', addWithdraw)

watch(route, (to, from) => {
  checkKYCStatus(to);
});
</script>